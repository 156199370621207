import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from "gatsby";
import withRoot from '../withRoot';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { PhoneIphone, Android } from '@material-ui/icons';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  header: {
    fontFamily: 'Catamaran,Helvetica,Arial,sans-serif',
    color: '#FFF',
    display: 'block',
  },
  phone: {
    color: '#FFF',
    fontSize: theme.spacing.unit * 40
  },
  iconLeft : {
    marginRight: theme.spacing.unit,
  },
  iconRight : {
    marginLeft: theme.spacing.unit,
  },
  androidButton: {
    background: "#FFF",
    color: theme.palette.primary.main
  },
  iosButton: {
    background: "#FFF",
    color: theme.palette.primary.main
  }
});

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      processing: true,
    };
  }

  componentDidMount() {
  }

  render() {
    const { classes, data } = this.props;
    return (
      <Layout>
      <SEO title="Home" keywords={[`wedink`, `wedding`]} />
      <div className={classes.root}>
        <Grid container spacing={8}>
        <Grid container item={true} xs={12} sm={9} style={{padding: "1.45rem", justifyContent: "center", alignContent: "start" }}>
          <Grid style={{justifyContent: "start" }}>
            <Typography variant="h4" className={classes.header}>
              {data.site.siteMetadata.description}
            </Typography>
          </Grid>
          <a href="https://play.google.com/store/apps/details?id=com.wedink.app" rel="noopener noreferrer" target="_blank" style={{ marginLeft: '1rem', marginBottom: "1rem", marginTop: "1rem" }}>
              <Fab variant="extended" className={classes.androidButton}>
                <Android className={classes.iconLeft} /> Get on Android
              </Fab>
            </a>
            <a href="https://itunes.apple.com/us/app/wedink/id1456491553" style={{ marginLeft: '1rem', marginBottom: "1rem", marginTop: "1rem"}}>
              <Fab variant="extended" className={classes.iosButton}>
                <PhoneIphone className={classes.iconLeft}/> Get on iOS
              </Fab>
            </a>
        </Grid>
        <Grid container item={true} sm={3} justify="center" alignItems="center">
          <div style={{ width: `300px`, padding: `1rem`, justifyContent: "center", alignContent: "start", alignItems: "center" }}>
           <Img fluid={data.SignUpScreenImage.childImageSharp.fluid} style={{borderRadius: '8px'}} />
          </div>
        </Grid>
        </Grid>
      </div>
      </Layout>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const query = graphql`
  query SiteDescQuery {
    site {
      siteMetadata {
        description
      }
    },
    SignUpScreenImage: file(relativePath: { eq: "sign-up-screen.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default withRoot(withStyles(styles)(Index));